<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 order-md-1 pt-4 privacy-content">
                    <h4>Terms & Conditions</h4>
                    <h5 class="mb-3">General</h5>
                    <p>
                        Please read these Terms & Conditions (hereinafter termed
                        as “Terms”) carefully before using the Falconcity.com
                        web site (the "Website") and its facilities & services
                        including services through
                        <span class="text-danger"
                            >Mobile app, Mobile application (the "Services")
                            operated by Falconcity of Wonders LLC.</span
                        >
                        By accessing the website and /or using any of the
                        services provided thereon ,you shall be deemed to have
                        accepted to be legally bound by these Terms as well as
                        any terms and conditions of the Website or associated
                        therewith, including the Privacy Policy (Collectively
                        the "Usage Terms"). <br />
                        <strong
                            >If you disagree with the Usage or the Terms partly
                            or fully, then please discontinue using the Website
                            and / or the Services immediately.</strong
                        >
                    </p>
                    <h5 class="mb-3">Interpretations</h5>
                    <p>
                        "Customer" means the user of the Website and / or
                        Services, visitors, & others who access or use the
                        Website or Services. "FCW" means Falconcity of Wonders
                        LLC & its sister companies and / or associated entities
                        and / or affiliated and or Group company, who may be
                        provider(s) of the Services; "Services" means the
                        electronic services offered by FCW on the Website.
                    </p>

                    <p>
                        <strong>Process Transactions - </strong>FCW may use
                        Personal Information to process your transactions.
                    </p>
                    <p>
                        <strong>Provide Support or Other Services - </strong>
                        FCW may use your Personal Information to provide you
                        support or other services you have ordered and other
                        similar operational communications.
                    </p>
                    <h5 class="mb-3">Services</h5>
                    <p>
                        The Services provided herein under the website are
                        conditional upon the Customer's acceptance of and
                        compliance with these Terms and Conditions, as may be
                        amended or modified from time to time. Upon use of the
                        website, the Customer is deemed to accept these Terms
                        and Conditions, as well as any amendments or
                        modifications thereto, FCW shall have the right, at any
                        time and subject to its sole discretion, to suspend,
                        terminate and / or alter the Services in any manner
                        whatsoever, including rejecting any payment(s) made by
                        the Customer without giving prior notice to the
                        Customer, justification and without any liability
                        whatsoever on the part of FCW.
                    </p>
                    <h5 class="mb-3">Changes to the Terms and Conditions</h5>
                    <p>
                        FCW reserve the right to update or change or modify in
                        the Terms to be reflected in this page at any time
                        without giving prior notice. Your continued use of the
                        Service after we post any modifications to the Terms on
                        this page will constitute your acknowledgement of the
                        modifications and your consent to abide and be bound by
                        the modified Terms.
                    </p>
                    <h5 class="mb-3">Obligation of the Customer</h5>
                    <p>
                        The Customer is solely responsible for the information
                        and contents therein submitted by he /she in FCW
                        website. The Customer shall ensure that any information
                        posted or submitted or permitted or caused to be posted
                        or submitted by the Customer is true and accurate
                        including the correctness of any customer related ID,
                        password(s) and / or personal information. Additionally,
                        and while payment(s) should normally reach FCW within a
                        period of 1 working day after clearing from the relevant
                        banks and / or financial institutions, the Customer
                        shall be solely responsible for ensuring that payment is
                        correctly and timely received by FCW. Accordingly, FCW
                        accepts no liability whatsoever in this respect and is
                        under no obligation to notify the Customer of any
                        incorrect, rejected and / or declined payment whether by
                        FCW or the provider of banking card(s) used by the
                        Customer. <br />
                        The Customer shall promptly provide to FCW, upon
                        request, any additional information which FCW may
                        consider necessary in providing or completing the
                        Services. The Customer acknowledges that FCW is under no
                        obligation to provide the Services to the Customer and
                        that receipt of any payment whatsoever shall not be
                        deemed or construed as an acceptance of payment or
                        waiver of FCW’s right to reject payment later on.
                    </p>
                    <h5 class="mb-3">Customer's Indemnity</h5>
                    <p>
                        The Customer shall indemnify FCW at all times from any
                        claim, action, demand, damages, loss, expense or cost
                        which FCW may suffer or incur as a result of the use of
                        the Services by the Customer.
                    </p>
                    <h5 class="mb-3">Intellectual Property Rights</h5>
                    <p>
                        All intellectual property rights in the materials and
                        information contained in this website or supplied to the
                        Customer by the Services shall belong solely to FCW and
                        these material and information shall not be reproduced
                        or used by the Customer for any other purpose unless
                        with the prior written approval of FCW.
                    </p>
                    <h5 class="mb-3">Payment Services</h5>
                    <p>
                        The Customer shall make use of the Services in
                        accordance with these Terms and Conditions as well as
                        any other terms and conditions associated with the
                        Services, including but not limited to the sale
                        agreement forming the basis of such Service.
                    </p>
                    <p class="text-danger">
                        We accept payments online using Major credit / debit
                        card Visa and MasterCard credit/debit card in AED.
                        <br />
                        Minors under the age of 18 shall are prohibited to
                        register as a User of this website and are not allowed
                        to transact or use the website. <br />
                        If you make a payment for our products or services on
                        our website, the details you are asked to submit will be
                        provided directly to Secure Payment Provider via a
                        secured connection. <br />
                        The cardholder must retain a copy of transaction records
                        and Merchant policies and rules. <br />
                    </p>
                    <h5 class="mb-3">Failed Transactions Response.</h5>
                    <p>
                        The Customer is under an obligation to promptly notify
                        FCW in writing in the case of any failed transaction or
                        if the Customer encounters any technical problem
                        associated with the Services.
                    </p>
                    <h5 class="mb-3">Refund Policy</h5>
                    <p>
                        The Customer shall have no right to any refund for any
                        payment(s) made to FCW except as may be provided
                        elsewhere or in accordance with FCW policies(s) and
                        agreement(s) with the Customer. Refund for any
                        transaction paid through this website would be refunded
                        to the original mode of payment.
                    </p>
                    <h5 class="mb-3">Receipt of Payment</h5>
                    <p>
                        FCW shall not be required to supply any receipt for
                        payments made by the Customer to FCW through the
                        Services or by electronic means.<br />
                        Receipt of the automated acknowledgment of successful
                        transaction completion by the customer may be considered
                        as a confirmation of the processing of transaction.
                    </p>
                    <h5 class="mb-3">Jurisdiction & Applicable Law</h5>
                    <p>
                        The law governing these Terms and Conditions shall be
                        the laws The Emirate of Dubai, UAE and any dispute
                        arising from the use of the Services or in relation to
                        these Terms and Conditions shall be submitted to the
                        exclusive jurisdiction of Dubai Courts, UAE
                    </p>
                    <p class="text-danger">
                        United Arab of Emirates is our country of domicile.
                        "(Website) will NOT deal or provide any services or
                        products to any of OFAC sanctions countries in
                        accordance with the law of UAE".
                    </p>
                    <p>
                        <strong
                            >Protect our Employees, Sites, Facilities, and
                            Operations -
                        </strong>
                        FCW may disclose your Personal Information as we believe
                        to be necessary or appropriate to protect the health and
                        safety of our employees and visitors, our physical and
                        online operations, our property, rights, and privacy,
                        and/or that of our affiliates, you or others. If you
                        visit our offices, you may be photographed or videotaped
                        as part of maintaining the security of our facilities.
                        Facility security photography will be treated as
                        sensitive information and will only be used for security
                        and investigation purposes.
                    </p>
                    <p>
                        <strong
                            >Comply with Legal Requirements and Corporate
                            Transactions -
                        </strong>
                        FCW may disclose your Personal Information as we believe
                        to be necessary or appropriate: (a) under applicable
                        law, including laws outside your country of residence;
                        (b) to comply with legal process; (c) to respond to
                        requests from public and government authorities,
                        including public and government authorities outside your
                        country of residence; (d) to enforce our terms and
                        conditions; and (e) to allow us to pursue available
                        remedies or limit the damages that we may sustain.
                        Additionally, in the event of a reorganization, merger,
                        sale, joint venture, assignment, transfer or other
                        disposition of all or any portion of our business,
                        assets or stock (including in connection with any
                        bankruptcy or similar proceedings), we may transfer the
                        Personal Information we have collected to the relevant
                        third party. Some of the Personal Information uses
                        specified above are not mandatory and may be controlled
                        by you.
                    </p>
                    <p class="text-danger">
                        All credit/debit cards details and personally
                        identifiable information will NOT be stored, sold,
                        shared, rented or leased to any third parties.
                    </p>
                    <h5 class="mb-3">Other Information</h5>
                    <p>
                        We may use, transfer, and disclose Other Information we
                        collect for any purpose, except where applicable law
                        requires otherwise. If we are required to treat Other
                        Information as Personal Information under applicable
                        law, then we will only use it in the same way that we
                        are permitted to use and disclose Personal Information.
                    </p>
                    <h5 class="mb-3">Acceptance</h5>
                    <p>
                        By using the information and facilities of the Site,
                        signify your agreement to FCW Privacy Policy. You are
                        hereby requested to not use this Site, if you do not
                        agree with this Policy. Your continued use of our Site
                        following posting of changes to these terms will mean
                        you accept those changes.
                    </p>
                    <p>
                        <strong>Protection & safety of your Information</strong>
                        We take utmost importance & care for the protection of
                        all information associated with our customers received
                        through our Site. We at Falconcity.com therefore
                        exercise the utmost care in providing secure
                        transmission of your information from your computer to
                        our servers. Unfortunately no data transmission over the
                        internet can be guaranteed to be 100% secure. As a
                        result while we strive to protect your information, we
                        can’t ensure or warrant the security of any information
                        you transmit to us or from our online products or
                        services and you do so at your own risk. Once we receive
                        your transmission, we use industry standard efforts to
                        safeguard the confidentiality of your information such
                        as using necessary
                        <span class="text-danger"
                            >Firewalls and Secure socket Layers or TLS.</span
                        >
                        However, perfect security does not exist on the
                        internet.
                    </p>
                    <p>
                        As a policy, your information with us, whether private
                        or public will not be sold, exchanged, transferred, or
                        given to any other user for any reason whatsoever
                        without your consent, other than for dealing with your
                        transactions or services requested or when we believe in
                        good faith that the law requires it. This exclusion does
                        not include trusted third parties who assist us in
                        operating our Site , conducting or supporting our
                        business or servicing you in any manner, so long as
                        those parties agrees to keep this information
                        confidential.
                    </p>
                    <h5 class="mb-3">Third Party Links</h5>
                    <p>
                        We, at our discretion, may include third party products
                        or services on our Site. These third party sites have
                        separate independent privacy policies. We therefore have
                        no responsibility or liability for the content and
                        activities of these linked sites. Nevertheless we seek
                        to protect the integrity of our Site under our Policy.
                        <strong>Your control over your information</strong> In
                        case you would like to change or edit some of the
                        personal information, we will try to provide a way to
                        correct, update or remove such information that you may
                        have provided to us. We allow the customers to opt out
                        by un registering from Falconcity.com and our periodic
                        emails, messages or newsletters to you would be
                        accordingly be stopped. If a customer wishes to update
                        personal information or objects to any usage provided
                        herein for any reason he/she may email the requested
                        change to
                        <span class="text-primary">fcwop@almoosa.ae</span>
                        email.
                    </p>
                    <h5 class="mb-3">Changes in Privacy Policy</h5>
                    <p class="text-danger">
                        "The Website Policies and Terms & Conditions may be
                        changed or updated occasionally to meet the requirements
                        and standards. Therefore the Customers' are encouraged
                        to frequently visit these sections in order to be
                        updated about the changes on the website. Modifications
                        will be effective on the day they are posted."
                    </p>
                    <h5 class="mb-3">Contacting Us</h5>
                    <p>
                        For any questions about this Policy, please email to
                        <span class="text-primary">fcwop@almoosa.ae</span> or
                        write to : Office No: 214,Almoosa Business Centre,Um
                        Hurair Road,Oud Metha, P.O Box 120660
                    </p>
                </div>
            </div>
            <footer class="my-3 pt-3 text-muted text-center text-small">
                <p class="mb-1">
                    Copyright Falconcity of Wonders. All Rights Reserved
                </p>
                <ul class="list-inline">
                    <li class="list-inline-item">
                        <a href="/privacy-policy">Privacy</a>
                    </li>
                    <li class="list-inline-item">
                        <a href="/terms-and-conditions">Terms & Conditions</a>
                    </li>
                </ul>
            </footer>
        </div>
    </div>
</template>
<script>
export default {
    data() {},
};
</script>
<style scoped></style>
